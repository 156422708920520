import ReactOnRails from 'react-on-rails';
import Modal from 'react-modal';

import { AddLastSeenAnuncioContainer, AnunciosListContainer, BotaoPontoDeVendasContainer } from "containers";
import { AnunciosListHotsiteContainer } from "containers";
import { AutenticationNavBarContainer } from 'containers';
import { LocationIndicatorContainer } from 'containers';
import { SearchBarContainer } from 'containers';
import { BannersContainer } from 'containers';
import { LastSeenAnunciosContainer } from 'containers/LastSeenAnunciosContainer';
import { BotaoAcessibilidadeContainer } from 'containers';
import { AberturaVendasContainer } from 'containers';
import { CookiesContainer } from 'containers';
import { MenuLateralAnuncioContainer } from 'containers';
import { BtnComprarContainer } from 'containers';
import { BtnLojaContainer } from 'containers/BtnLojaContainer';

Modal.setAppElement(document.getElementsByTagName('body')[0]);

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    //@ts-ignore
    AddLastSeenAnuncioContainer,
    //@ts-ignore
    AnunciosListContainer,
    AutenticationNavBarContainer,
    BannersContainer,
    LastSeenAnunciosContainer,
    //@ts-ignore
    LocationIndicatorContainer,
    //@ts-ignore
    SearchBarContainer,
    //@ts-ignore
    BotaoAcessibilidadeContainer,
    BotaoPontoDeVendasContainer,
    //@ts-ignore
    AberturaVendasContainer,
    //@ts-ignore
    CookiesContainer,
    MenuLateralAnuncioContainer,
    AnunciosListHotsiteContainer,
    BtnComprarContainer,
    BtnLojaContainer
});
